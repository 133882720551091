import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'underscore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    brand: false,
    alertList: [],
    appBarEnabled: false,
    appInitialized: false,
    appNavigationDrawerEnabled: false,
    user: {},
    adminUser: {},
    shoppingCartId: null,
    shoppingCart: {
      coupon: null,
      coupons: [],
      items: [],
      total_count: '0',
      total_amount: 0,
      total_amount_after_cart_discount: 0,
    },
    menuTemplate: {
      Admin: [
        {
          active: true,
          group: false,
          icon: 'mdi-monitor-dashboard',
          title: 'Dashboard',
          to: {
            name: 'Admin.Home',
          },
        },
        {
          active: false,
          group: true,
          icon: 'mdi-account-supervisor-circle',
          title: 'Partner',
          submenus: [
            {
              title: 'Partner List',
              active: false,
              to: {
                name: 'Admin.PartnerList',
              },
            },
            {
              title: 'Retail Client List',
              active: false,
              to: {
                name: 'Admin.PartnerClientList',
              },
            },
            {
              title: 'Auth User List',
              active: false,
              to: {
                name: 'Admin.ListAuthUsers',
              },
            },
            {
              title: 'Add Partner',
              active: false,
              to: {
                name: 'Admin.PartnerAdd',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-server',
          title: 'Server',
          submenus: [
            {
              title: 'Server List',
              active: false,
              to: {
                name: 'Admin.ServerList',
              },
            },
            {
              title: 'Add Server',
              active: false,
              to: {
                name: 'Admin.ServerAdd',
              },
            },
            {
              title: 'Server Group List',
              active: false,
              to: {
                name: 'Admin.ServerGroupList',
              },
            },
            {
              title: 'Add Server Group',
              active: false,
              to: {
                name: 'Admin.ServerGroupAdd',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-nas',
          title: 'Product',
          submenus: [
            {
              title: 'Product List',
              active: false,
              to: {
                name: 'Admin.ProductList',
              },
            },
            {
              title: 'Add Product',
              active: false,
              to: {
                name: 'Admin.ProductAdd',
              },
            },
            {
              title: 'Addon List',
              active: false,
              to: {
                name: 'Admin.AddonList',
              },
            },
            {
              title: 'Add Addon',
              active: false,
              to: {
                name: 'Admin.AddonAdd',
              },
            },
            {
              title: 'Product Type List',
              active: false,
              to: {
                name: 'Admin.ProductTypeList',
              },
            },
            {
              title: 'Add Product Type',
              active: false,
              to: {
                name: 'Admin.ProductTypeAdd',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-web',
          title: 'Domain Product',
          submenus: [
            {
              title: 'Domain Product List',
              active: false,
              to: {
                name: 'Admin.DomainProductList',
              },
            },
            {
              title: 'Add Domain Product',
              active: false,
              to: {
                name: 'Admin.DomainProductAdd',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-bank',
          title: 'Balance',
          submenus: [
            {
              title: 'Partner Payment History',
              active: false,
              to: {
                name: 'Admin.PartnerPaymentHistory',
              },
            },
            {
              title: 'Partner Credits Deduction',
              active: false,
              to: {
                name: 'Admin.PartnerCreditDeduction',
              },
            },
            {
              title: 'Manual Invoice',
              active: false,
              to: {
                name: 'Admin.ManualInvoice',
              },
            },
            {
              title: 'Retail Invoices',
              active: false,
              to: {
                name: 'Admin.TaxInvoiceList',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-nas',
          title: 'Services',
          submenus: [
            {
              title: 'Active Services',
              active: false,
              to: {
                name: 'Admin.ListActiveServices',
              },
            },
            {
              title: 'Active Domains',
              active: false,
              to: {
                name: 'Admin.ListActiveDomains',
              },
            },
            {
              title: 'Import Cpanel Service',
              active: false,
              to: {
                name: 'Admin.ImportCpanelService',
              },
            },
            {
              title: 'Import Manual Service',
              active: false,
              to: {
                name: 'Admin.ImportManualService',
              },
            },
            {
              title: 'Import Domain Service',
              active: false,
              to: {
                name: 'Admin.ImportDomainService',
              },
            },
            {
              title: 'Bulk Import Cpanel Service',
              active: false,
              to: {
                name: 'Admin.BulkImportCpanelService',
              },
            },
            {
              title: 'Toggle Expired',
              active: false,
              to: {
                name: 'Admin.ToggleExpired',
              },
            },
            {
              title: 'Vendor Inventory',
              active: false,
              to: {
                name: 'Admin.VendorList',
              },
            },
            {
              title: 'Query Domain Contact',
              active: false,
              to: {
                name: 'Admin.QueryDomainContact',
              },
            },
            {
              title: 'Cancel Firstcom Service',
              active: false,
              to: {
                name: 'Admin.CancelFirstcomService',
              },
            },
            {
              title: 'Force DS Admin Contact',
              active: false,
              to: {
                name: 'Admin.ForceDsDomainAdminChange',
              },
            },
            {
              title: 'List Migration Services',
              active: false,
              to: {
                name: 'Admin.ListMigrationServices',
              },
            },
            {
              title: '0year transfer DS',
              active: false,
              to: {
                name: 'Admin.ZeroYearTransfer',
              },
            },
            {
              title: '0year transfer Sgnic',
              active: false,
              to: {
                name: 'Admin.ZeroYearTransferSgnic',
              },
            },
            {
              title: 'Update Sgnic Registrant',
              active: false,
              to: {
                name: 'Admin.QuerySgnicDomainContact',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-account-multiple-plus',
          title: 'Affiliates',
          submenus: [
            {
              title: 'Affiliate List',
              active: false,
              to: {
                name: 'Admin.AffiliateList',
              },
            },
            {
              title: 'Withdraw List',
              active: false,
              to: {
                name: 'Admin.AffiliateCommissionWithdrawList',
              },
            },
            {
              title: 'Commission List',
              active: false,
              to: {
                name: 'Admin.AffiliateCommissionList',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-account-multiple-plus',
          title: 'Partner Commission',
          submenus: [
            {
              title: 'Withdraw List',
              active: false,
              to: {
                name: 'Admin.PartnerCommissionWithdrawList',
              },
            },
            {
              title: 'Commission List',
              active: false,
              to: {
                name: 'Admin.PartnerCommissionList',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-cash-100',
          title: 'Discounts',
          submenus: [
            {
              title: 'Discount List',
              active: false,
              to: {
                name: 'Admin.DiscountList',
              },
            },
            {
              title: 'Add Discount',
              active: false,
              to: {
                name: 'Admin.DiscountAdd',
              },
            },
          ],
        },
        {
          active: false,
          group: false,
          icon: 'mdi-calendar-clock',
          title: 'Service Manual Provision Queue',
          to: {
            name: 'Admin.ServiceManualProvisionQueueList',
          },
        },
        {
          active: false,
          group: true,
          icon: 'mdi-piggy-bank',
          title: 'Leaders only',
          permissions: [
            'leader',
          ],
          submenus: [
            {
              title: 'Stripe Uncapture Payment',
              active: false,
              to: {
                name: 'Admin.StripeNoMatchingPayments',
              },
            },
            {
              title: 'Revenue Report',
              active: false,
              to: {
                name: 'Admin.RevenueReport',
              },
            },
            {
              title: 'Above 2k Report',
              active: false,
              to: {
                name: 'Admin.Above2kReport',
              },
            },
            {
              title: 'Cpanel Server Monthly Revenue Report',
              active: false,
              to: {
                name: 'Admin.CpanelServerRevenueMonthly',
              },
            },
            {
              title: 'Cldy New Customer Report',
              active: false,
              to: {
                name: 'Admin.CldyNewCustomerReport',
              },
            },
            {
              title: 'Email Notify Customer',
              active: false,
              to: {
                name: 'Admin.NotifyCustomer',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-piggy-bank',
          title: 'Leads',
          submenus: [
            {
              title: 'cpanel Diskusage',
              active: false,
              to: {
                name: 'Admin.CpanelDiskusage',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-auto-fix',
          title: 'Audit',
          counter_slug: 'audit_total',
          counter: 0,
          submenus: [
            {
              title: 'Domain Resellerclub',
              active: false,
              counter_slug: 'audit_domain_rs',
              counter: '',
              to: {
                name: 'Admin.DomainAuditResellerclub',
              },
            },
            {
              title: 'Domain Dreamscape',
              active: false,
              counter_slug: 'audit_domain_ds',
              counter: '',
              to: {
                name: 'Admin.DomainAuditDreamscape',
              },
            },
            {
              title: 'SG Server Renew Lock',
              active: false,
              counter_slug: 'audit_server_renew_prohibited',
              counter: 0,
              to: {
                name: 'Admin.DomainAuditServerRenewProhibited',
              },
            },
            {
              title: 'Unpaid Invoices',
              active: false,
              counter_slug: 'audit_unpaid_invoices',
              counter: 0,
              to: {
                name: 'Admin.AuditUnpaidInvoices',
              },
            },
            {
              title: 'Pending Services',
              active: false,
              counter_slug: 'audit_pending_services',
              counter: 0,
              to: {
                name: 'Admin.AuditPendingServices',
              },
            },
            {
              title: 'Pending Domains',
              active: false,
              counter_slug: 'audit_pending_domains',
              counter: 0,
              to: {
                name: 'Admin.AuditPendingDomains',
              },
            },
            {
              title: 'Renewal Chase',
              active: false,
              counter_slug: 'audit_near_expiry_manual',
              counter: 0,
              to: {
                name: 'Admin.AuditNearExpiryManualServices',
              },
            },
            {
              title: 'Not Suspended Cpanel',
              active: false,
              counter_slug: 'audit_not_suspend_cpanel',
              counter: 0,
              to: {
                name: 'Admin.AuditNotSuspendCpanel',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-tools',
          title: 'Utility',
          submenus: [
            {
              title: 'Whois',
              active: false,
              to: {
                name: 'Admin.Whois',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-file-document-multiple',
          title: 'Articles',
          submenus: [
            {
              title: 'List Article Category',
              active: false,
              to: {
                name: 'Admin.ArticleCategoryList',
              },
            },
            {
              title: 'Create Article Category',
              active: false,
              to: {
                name: 'Admin.ArticleCategoryAdd',
              },
            },
            {
              title: 'List Article',
              active: false,
              to: {
                name: 'Admin.ArticleList',
              },
            },
            {
              title: 'Create Article',
              active: false,
              to: {
                name: 'Admin.ArticleAdd',
              },
            },
          ],
        },
        // {
        //   active: false,
        //   group: false,
        //   icon: 'mdi-web-clock',
        //   title: 'Domain Provision Queue',
        //   to: {
        //     name: 'Admin.DomainProvisionQueue',
        //   },
        // },
      ],
      Partner: [
        {
          active: true,
          group: false,
          icon: 'mdi-monitor-dashboard',
          title: 'Dashboard',
          to: {
            name: 'Partner.Home',
          },
        },
        {
          active: false,
          group: true,
          icon: 'mdi-server-network',
          title: 'Service',
          submenus: [
            {
              title: 'Service List',
              active: false,
              to: {
                name: 'Partner.ServiceList',
              },
            },
            {
              title: 'Service Archived List',
              active: false,
              to: {
                name: 'Partner.ServiceArchiveList',
              },
            },
            {
              title: 'Service Register',
              active: false,
              to: {
                name: 'Partner.ServiceRegister',
              },
            },
            {
              title: 'Service Renew',
              active: false,
              to: {
                name: 'Partner.ServiceRenew',
              },
            },
            {
              title: 'Addon List',
              active: false,
              to: {
                name: 'Partner.AddonList',
              },
            },
            {
              title: 'Addon Archived List',
              active: false,
              to: {
                name: 'Partner.AddonArchiveList',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-web',
          title: 'Domain',
          submenus: [
            {
              title: 'Domain List',
              active: false,
              to: {
                name: 'Partner.DomainList',
              },
            },
            {
              title: 'Domain Archived List',
              active: false,
              to: {
                name: 'Partner.DomainListArchive',
              },
            },
            {
              title: 'Domain Register',
              active: false,
              to: {
                name: 'Partner.DomainRegister',
              },
            },
            {
              title: 'Domain Renew',
              active: false,
              to: {
                name: 'Partner.DomainRenew',
              },
            },
            {
              title: 'Domain Transfer',
              active: false,
              to: {
                name: 'Partner.DomainTransfer',
              },
            },
            {
              title: 'Domain Default Contact',
              active: false,
              to: {
                name: 'Partner.DomainDefaultContact',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-bank',
          title: 'Balance',
          submenus: [
            {
              title: 'Balance List',
              active: false,
              to: {
                name: 'Partner.BalanceList',
              },
            },
            {
              title: 'Payment History',
              active: false,
              to: {
                name: 'Partner.TopupHistory',
              },
            },
            {
              title: 'Balance Topup',
              active: false,
              to: {
                name: 'Partner.Topup',
              },
            },
            {
              title: 'Tax Invoice List',
              active: false,
              to: {
                name: 'Partner.TaxInvoiceList',
              },
            },
            {
              active: false,
              icon: 'mdi-cash-multiple',
              title: 'Commission List',
              to: {
                name: 'Partner.PartnerCommissionList',
              },
            },
          ],
        },
        {
          active: true,
          group: false,
          icon: 'mdi-badge-account-horizontal',
          title: 'Profile',
          to: {
            name: 'Partner.Profile',
          },
        },
        {
          active: true,
          group: false,
          icon: 'mdi-zip-disk',
          title: 'Settings',
          to: {
            name: 'Partner.Settings',
          },
        },
      ],
      Retail: [
        {
          active: true,
          group: false,
          icon: 'mdi-monitor-dashboard',
          title: 'Dashboard',
          to: {
            name: 'Retail.Home',
          },
        },
        {
          active: false,
          group: true,
          icon: 'mdi-server-network',
          title: 'Service',
          submenus: [
            {
              title: 'Service List',
              active: false,
              to: {
                name: 'Retail.ServiceList',
              },
            },
            {
              title: 'Service Archived List',
              active: false,
              to: {
                name: 'Retail.ServiceArchiveList',
              },
            },
            {
              title: 'Service Register',
              active: false,
              to: {
                name: 'Retail.ServiceRegister',
              },
            },
            {
              title: 'Service Renew',
              active: false,
              to: {
                name: 'Retail.ServiceRenew',
              },
            },
            {
              title: 'Addon List',
              active: false,
              to: {
                name: 'Retail.AddonList',
              },
            },
            {
              title: 'Addon Archived List',
              active: false,
              to: {
                name: 'Retail.AddonArchiveList',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-web',
          title: 'Domain',
          submenus: [
            {
              title: 'Domain List',
              active: false,
              to: {
                name: 'Retail.DomainList',
              },
            },
            {
              title: 'Domain Archived List',
              active: false,
              to: {
                name: 'Retail.DomainListArchive',
              },
            },
            {
              title: 'Domain Register',
              active: false,
              to: {
                name: 'Retail.DomainRegister',
              },
            },
            {
              title: 'Domain Renew',
              active: false,
              to: {
                name: 'Retail.DomainRenew',
              },
            },
            {
              title: 'Domain Transfer',
              active: false,
              to: {
                name: 'Retail.DomainTransfer',
              },
            },
            {
              title: 'Domain Default Contact',
              active: false,
              to: {
                name: 'Retail.DomainDefaultContact',
              },
            },
          ],
        },
        {
          active: false,
          group: true,
          icon: 'mdi-bank',
          title: 'Invoice',
          submenus: [
            // {
            //   title: 'Balance List',
            //   active: false,
            //   to: {
            //     name: 'Retail.BalanceList',
            //   },
            // },
            {
              title: 'Payment History',
              active: false,
              to: {
                name: 'Retail.TopupHistory',
              },
            },
            // {
            //   title: 'Balance Topup',
            //   active: false,
            //   to: {
            //     name: 'Retail.Topup',
            //   },
            // },
            {
              title: 'Tax Invoice List',
              active: false,
              to: {
                name: 'Retail.TaxInvoiceList',
              },
            },
          ],
        },
        {
          active: true,
          group: false,
          icon: 'mdi-credit-card-edit',
          title: 'Card',
          to: {
            name: 'Retail.Card',
          },
        },
        {
          active: true,
          group: false,
          icon: 'mdi-badge-account-horizontal',
          title: 'Profile',
          to: {
            name: 'Retail.Profile',
          },
        },
        {
          active: true,
          group: false,
          icon: 'mdi-zip-disk',
          title: 'Settings',
          to: {
            name: 'Retail.Settings',
          },
        },
        // {
        //   active: true,
        //   group: false,
        //   icon: 'mdi-security',
        //   title: 'Security',
        //   to: {
        //     name: 'Retail.Security',
        //   },
        // },
      ],
      Cp: [
        {
          active: true,
          group: false,
          icon: 'mdi-server-network',
          title: 'Cpanel List',
          to: {
            name: 'CpLoginCpanel',
          },
        },
      ],
    },
    resellerMenus: [
      {
        active: false,
        group: true,
        icon: 'mdi-account-supervisor-circle',
        title: 'Collaborator',
        submenus: [
          {
            title: 'Collaborator List',
            active: false,
            to: {
              name: 'Partner.CollaboratorList',
            },
          },
          {
            title: 'Collaborator Service List',
            active: false,
            to: {
              name: 'Partner.CollaboratorServiceList',
            },
          },
          {
            title: 'Collaborator Action Log List',
            active: false,
            to: {
              name: 'Partner.CollaboratorActionList',
            },
          },
          {
            title: 'New Collaborator',
            active: false,
            to: {
              name: 'Partner.CollaboratorNew',
            },
          },
        ],
      },
      {
        active: false,
        group: true,
        icon: 'mdi-storefront',
        title: 'Reseller',
        submenus: [
          {
            title: 'Customer List',
            active: false,
            to: {
              name: 'Partner.ResellerCustomerList',
            },
          },
          {
            title: 'Customer Service List',
            active: false,
            to: {
              name: 'Partner.ResellerCustomerServiceList',
            },
          },
          {
            title: 'Customer Domain List',
            active: false,
            to: {
              name: 'Partner.ResellerCustomerDomainList',
            },
          },
          {
            title: 'Service/Domain Transfer Queue',
            active: false,
            to: {
              name: 'Partner.ResellerCustomerTransferQueue',
            },
          },
          {
            title: 'Tax Invoice List',
            active: false,
            to: {
              name: 'Partner.ResellerCustomerInvoiceList',
            },
          },
          {
            title: 'Product Price List',
            active: false,
            to: {
              name: 'Partner.ResellerProductPrices',
            },
          },
          {
            title: 'Domain Price List',
            active: false,
            to: {
              name: 'Partner.ResellerDomainProductPrices',
            },
          },
          {
            title: 'Addon Price List',
            active: false,
            to: {
              name: 'Partner.ResellerAddonPrices',
            },
          },
        ],
      },
    ],
    cldyMenus: [
      {
        active: false,
        group: true,
        icon: 'mdi-account-supervisor-circle',
        title: 'Collaborator',
        submenus: [
          {
            title: 'Collaborator List',
            active: false,
            to: {
              name: 'Retail.CollaboratorList',
            },
          },
          {
            title: 'Collaborator Service List',
            active: false,
            to: {
              name: 'Retail.CollaboratorServiceList',
            },
          },
          {
            title: 'Collaborator Action Log List',
            active: false,
            to: {
              name: 'Retail.CollaboratorActionList',
            },
          },
          {
            title: 'New Collaborator',
            active: false,
            to: {
              name: 'Retail.CollaboratorNew',
            },
          },
        ],
      },
    ],
    menus: [],
  },
  mutations: {
    addAlert(state, alert) {
      if (state.alertList.length >= 1) {
        state.alertList.shift();
      }
      state.alertList.push(alert);

      setTimeout(() => {
        state.alertList.shift();
      }, 10000);
    },
    reset(state) {
      state.alertList = [];
      state.appBarEnabled = false;
      state.appNavigationDrawerEnabled = false;
      state.isAuthenticated = false;
      state.user = {};
      state.adminUser = {};
      state.menus = [];
    },
    setShoppingCartId(state, shoppingCartId) {
      if (shoppingCartId.indexOf('-') > 0) {
        state.shoppingCartId = shoppingCartId;
      }
    },
    setCurrentUser(state, user) {
      state.user = user;
      state.menus = state.menuTemplate[user.role];

      _.each(state.menus, (menu, index) => {
        if (state.menus[index].permissions) {
          if (_.intersection(state.menus[index].permissions, user.permissions).length === 0) {
            state.menus.splice(index, 1);
          }
        }
      });

      if (state.brand !== false) {
        _.each(state.menus, (menu, index) => {
          if (state.menus[index].to) {
            state.menus[index].to.params = { brand: state.brand };
          }

          if (state.menus[index].submenus) {
            _.each(state.menus[index].submenus, (submenu, subindex) => {
              state.menus[index].submenus[subindex].to.params = { brand: state.brand };
            });
          }
        });

        if (state.brand === 'cldy') {
          if (user.affiliate_code) {
            state.menus = state.menus.concat([
              {
                active: true,
                group: false,
                icon: 'mdi-cash-multiple',
                title: 'Affiliate',
                to: {
                  name: 'Retail.AffiliateCommissionList',
                },
              },
            ]);
          }

          if (user.friend_code) {
            state.menus = state.menus.concat([
              {
                active: true,
                group: false,
                icon: 'mdi-ticket-percent',
                title: 'Friend Voucher',
                to: {
                  name: 'Retail.FriendVoucherList',
                },
              },
            ]);
          }

          state.menus = state.menus.concat([
            {
              active: true,
              group: false,
              icon: 'mdi-card-account-phone',
              title: 'Virtual Business Card',
              to: {
                name: 'Retail.VcardList',
              },
            },
          ]);

          state.menus = state.menus.concat(state.cldyMenus);
        }
      }

      if (user.role === 'Partner') {
        state.menus = state.menus.concat(state.resellerMenus);
        if (user.user_settings && user.user_settings.userId === 586) {
          state.menus.splice(2, 1);
        }

        if (user.user_settings && user.user_settings.userId === 4) {
          state.menus[3].submenus.push({
            title: 'Monthly Commission',
            active: false,
            to: {
              name: 'Partner.PartnerCommissionListYearMonth',
            },
          });
        }
      }
    },
    setCurrentAdminUser(state, payload = { admin: false }) {
      if (payload.admin) {
        state.adminUser = payload.admin;
      } else {
        state.adminUser = state.user;
      }
    },
    resetCurrentAdminUser(state) {
      state.adminUser = {};
    },
    showDashboard(state) {
      state.appNavigationDrawerEnabled = true;
      state.appBarEnabled = true;
    },
    syncStore(state, appState) {
      state.appBarEnabled = appState.appBarEnabled;
      state.appNavigationDrawerEnabled = appState.appNavigationDrawerEnabled;
      if (appState.adminUser) {
        state.adminUser = appState.adminUser;
        state.user = appState.user;
      } else {
        state.user = appState.user;
      }
      state.menus = appState.menus;
      if (state.brand !== false) {
        _.each(state.menus, (menu, index) => {
          if (state.menus[index].to) {
            state.menus[index].to.params = { brand: state.brand };
          }

          if (state.menus[index].submenus) {
            _.each(state.menus[index].submenus, (submenu, subindex) => {
              state.menus[index].submenus[subindex].to.params = { brand: state.brand };
            });
          }
        });
      }
      state.appInitialized = true;
    },
    syncShoppingCart(state, shoppingCart) {
      state.shoppingCart.items = shoppingCart.items;
      state.shoppingCart.coupon = shoppingCart.coupon;
      state.shoppingCart.coupons = shoppingCart.coupons;
      state.shoppingCart.total_count = shoppingCart.total_count;
      state
        .shoppingCart
        .total_amount_after_cart_discount = shoppingCart.total_amount_after_cart_discount;
      state.shoppingCart.total_amount = shoppingCart.total_amount;
    },
    resetShoppingCart(state) {
      state.shoppingCartId = null;
      state.shoppingCart.items = [];
      state.shoppingCart.coupon = null;
      state.shoppingCart.coupons = [];
      state.shoppingCart.total_count = 0;
      state.shoppingCart.total_amount_after_cart_discount = 0;
      state.shoppingCart.total_amount = 0;
    },
    activeMenu(state, currentMenuTitle) {
      _.each(state.menus, (menu, index) => {
        state.menus[index].active = currentMenuTitle === menu.title;
      });
    },
    setBrand(state, brand) {
      state.brand = brand;
    },
  },
  actions: {
    activeMenu({ commit }, currentMenuTitle) {
      commit('activeMenu', currentMenuTitle);
    },
    addAlert({ commit }, alert) {
      commit('addAlert', alert);
    },
    reset({ commit }) {
      commit('reset');
    },
    setShoppingCartId({ commit }, shoppingCartId) {
      commit('setShoppingCartId', shoppingCartId);
    },
    setCurrentUser({ commit }, user) {
      commit('setCurrentUser', user);
    },
    setCurrentAdminUser({ commit }, payload = { admin: false }) {
      commit('setCurrentAdminUser', payload);
    },
    resetCurrentAdminUser({ commit }) {
      commit('resetCurrentAdminUser');
    },
    showDashboard({ commit }) {
      commit('showDashboard');
    },
    syncStore({ commit }, appState) {
      commit('syncStore', appState);
    },
    syncShoppingCart({ commit }, shoppingCart) {
      commit('syncShoppingCart', shoppingCart);
    },
    resetShoppingCart({ commit }) {
      commit('resetShoppingCart');
    },
    setBrand({ commit }, brand) {
      commit('setBrand', brand);
    },
  },
  modules: {
  },
});
