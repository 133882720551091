<template>
<v-row>
  <v-col cols="12">
    <v-skeleton-loader
      ref="skeleton"
      v-if="!showPage"
      type="card"
      class="mx-auto"
    ></v-skeleton-loader>
    <v-row dense align="center" v-if="showPage">
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <h3>{{ form.domain }}</h3>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <h5>Status:<br>{{ form.status_name }}</h5>
          </v-col>
          <v-col cols="3">
            <h5>Registration Date:<br>{{ form.provision_date }}</h5>
          </v-col>
          <v-col cols="3">
            <h5>Expiry Date:<br>{{ form.expiry_date }}</h5>
          </v-col>
          <v-col cols="3">
            <div class="my-1">
              <v-btn
                v-if="form.status_name === 'Active' || form.status_name === 'Expired'"
                x-small
                color="success"
                @click="renew(id)"
              >RENEW</v-btn>
            </div>
            <div class="my-1">
              <v-btn
                v-if="form.status_name === 'Active'"
                x-small
                color="error"
                @click="cancel(id)"
              >CANCEL</v-btn><br>
            </div>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="6" v-if="canEpp">
            <v-btn
              v-if="form.locked"
              color="error"
              @click="cancelPromp()"
            >Unlock Domain or Get EPP Code</v-btn>
            <v-btn
              v-else
              color="error"
              @click="cancelPromp()"
            >Get EPP Code</v-btn>
          </v-col>
          <v-col cols="6" v-else>
            <p>For transfer out, please get in touch with Support</p>
          </v-col>
          <v-col cols="3">
            <v-switch v-model="form.auto_renew" label="Auto Renew"
              :loading="autoRenewLoading"
              :readonly="true"
              @click.stop="toggleAutoRenew()"
            ></v-switch>
          </v-col>
        </v-row>
        <v-form ref="updateNsForm">
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              label="NS1*"
              v-model="form.ns1"
              :rules="[rules.required, rules.nameserver]"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="NS2*"
              v-model="form.ns2"
              :rules="[rules.required, rules.nameserver]"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="3">
            <v-text-field
              label="NS3"
              v-model="form.ns3"
              :rules="[rules.nameserver]"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="NS4"
              v-model="form.ns4"
              :rules="[rules.nameserver]"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="NS5"
              v-model="form.ns5"
              :rules="[rules.nameserver]"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p class="blue--text" v-if="form.domain.indexOf('.my') > 0">
              please contact support for name server change
            </p>
            <v-btn color="primary"
                   v-else
              :loading="updateNsLoading"
              @click="updateNs"
            >Update Nameservers</v-btn>
          </v-col>
        </v-row>
        </v-form>
        <v-row dense v-if="canViewRegContact || canViewOtherContact">
          <v-col cols="6" v-if="canViewRegContact">
            <v-form ref="updateRegistrantForm">
            <h5>Registrant Contact</h5>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn
                  v-if="defaultContact"
                  x-small
                  color="success"
                  @click="copyContact('defaultContact', 'registrant')">Use default contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('admin', 'registrant')">copy admin contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('tech', 'registrant')">copy tech contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('billing', 'registrant')">copy billing contact</v-btn>
              </v-col>
            </v-row>
            <profile
              ref="registrantProfileComp"
              :setNewProfile="registrant"
            ></profile>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="updateRegistrantLoading"
                  @click="updateRegistrant"
                >Update</v-btn>
              </v-col>
            </v-row>
            </v-form>
          </v-col>
          <v-col cols="6" v-if="canViewOtherContact">
            <v-form ref="updateAdminForm">
            <h5>Admin Contact</h5>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn
                  v-if="defaultContact"
                  x-small
                  color="success"
                  @click="copyContact('defaultContact', 'admin')">Use default contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('registrant', 'admin')">copy registrant contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('tech', 'admin')">copy tech contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('billing', 'admin')">copy billing contact</v-btn>
              </v-col>
            </v-row>
            <profile
              ref="adminProfileComp"
              :setNewProfile="admin"
            ></profile>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="updateAdminLoading"
                  @click="updateAdmin"
                >Update</v-btn>
              </v-col>
            </v-row>
            </v-form>
          </v-col>
          <v-col cols="6" v-if="canViewOtherContact">
            <v-form ref="updateTechForm">
            <h5>Technique Contact</h5>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn
                  x-small
                  v-if="defaultContact"
                  color="success"
                  @click="copyContact('defaultContact', 'tech')">Use default contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('registrant', 'tech')">copy registrant contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('admin', 'tech')">copy admin contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('billing', 'tech')">copy billing contact</v-btn>
              </v-col>
            </v-row>
            <profile
              ref="techProfileComp"
              :setNewProfile="tech"
            ></profile>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="updateTechLoading"
                  @click="updateTech"
                >Update</v-btn>
              </v-col>
            </v-row>
            </v-form>
          </v-col>
          <v-col cols="6" v-if="canViewOtherContact">
            <v-form ref="updateBillingForm">
            <h5>Billing Contact</h5>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn
                  x-small
                  v-if="defaultContact"
                  color="success"
                  @click="copyContact('defaultContact', 'billing')">Use default contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('registrant', 'billing')">copy registrant contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('admin', 'billing')">copy admin contact</v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="copyContact('tech', 'billing')">copy tech contact</v-btn>
              </v-col>
            </v-row>
            <profile
              ref="billingProfileComp"
              :setNewProfile="billing"
            ></profile>
            <v-row dense v-if="canChangeRegContact">
              <v-col cols="12">
                <v-btn color="primary"
                       :loading="updateBillingLoading"
                       @click="updateBilling()">Update</v-btn>
              </v-col>
            </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row dense v-if="form.domain.indexOf('.my') < 0">
          <v-col cols="12">
            <v-form ref="updateChildNsForm">
              <v-row dense>
                <v-col cols="3">
                  <v-btn small color="success"
                         @click="addChildNameserver"
                  >Add Child Nameserver</v-btn>
                </v-col>
              </v-row>
              <v-row dense v-for="(childNs, index) in childNss" :key="childNs.id" align="center">
                <v-col cols="4">
                  <v-text-field
                    label="Child Nameserver"
                    v-model="childNs.subdomain"
                    :readonly="true"
                    :disabled="true"
                    :suffix="form.domain"
                    :rules="[rules.childNs, rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="IP"
                    v-model="childNs.ip"
                    :rules="[rules.ip, rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    x-small
                    color="error"
                    @click="removeChildNs(index)"
                  >Remove</v-btn>
                </v-col>
              </v-row>
              <v-row dense v-for="(addChildNs, index) in addChildNss" :key="index" align="center">
                <v-col cols="4">
                  <v-text-field
                    label="Child Nameserver"
                    v-model="addChildNs.subdomain"
                    :suffix="`.${form.domain}`"
                    :rules="[rules.childNs, rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="IP"
                    :rules="[rules.ip, rules.required]"
                    v-model="addChildNs.ip"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    x-small
                    color="error"
                    @click="addChildNss.splice(index,1)"
                  >Remove</v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <v-btn
                    v-if="addChildNss.length !== 0
                    || childNss.length !== 0
                    || deleteChildNss.length !== 0"
                    color="primary"
                    :loading="updateChildNsLoading"
                    @click="updateChildNameserver"
                  >Update Child Nameserver</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row dense v-if="service.domain_config.service_configuration.registrar_id === 8">
          <v-col cols="12">
            <v-form ref="updateDNSSECForm">
              <v-row dense v-if="domainDnssec.length == 0">
                <v-col cols="3">
                  <v-btn small color="success"
                         @click="addDNSSEC"
                  >Add DNSSEC</v-btn>
                </v-col>
              </v-row>
              <v-row dense v-for="(domainDnssecRecord) in domainDnssec"
                     :key="domainDnssecRecord.id" align="center">
                <v-col cols="4">
                  <v-text-field v-model="domainDnssecRecord.keytag" readonly></v-text-field>
                </v-col>
                <v-col cols="4">
                  <p><b>Algorithm</b>: ECDSA Curve P-256 with SHA-256 256 bits (Algorithm 13)</p>
                </v-col>
                <v-col cols="4">
                  <p><b>Digest Type</b>: SHA-256 (Algorithm 2)</p>
                </v-col>
                <v-col cols="10">
                  <v-textarea v-model="domainDnssecRecord.digest" readonly></v-textarea>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    x-small
                    color="error"
                    @click="removeDnssec()"
                  >Remove</v-btn>
                </v-col>
              </v-row>
              <v-row dense v-for="(addDomainDnssecRecord, index) in addDomainDnssec"
                     :key="index" align="center">
                <v-col cols="4">
                  <v-text-field label="KeyTag" v-model="addDomainDnssecRecord.keytag">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <p><b>Algorithm</b>: ECDSA Curve P-256 with SHA-256 256 bits (Algorithm 13)</p>
                </v-col>
                <v-col cols="4">
                  <p><b>Digest Type</b>: SHA-256 (Algorithm 2)</p>
                </v-col>
                <v-col cols="10">
                  <v-textarea label="Digest" v-model="addDomainDnssecRecord.digest"></v-textarea>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    x-small
                    color="error"
                    @click="addDomainDnssec.splice(index,1)"
                  >Remove</v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  <v-btn
                    v-if="addDomainDnssec.length !== 0"
                    color="primary"
                    :loading="updateDnssecLoading"
                    @click="saveDomainDnssec"
                  >Save</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row
          v-for="addon in this.service.addons"
          :key="addon.id"
        >
          <v-col>
            <v-card>
              <v-card-text>
                <addon
                  :service-id="addon.id"
                  :parent-id="id"
                  @renew="renew"
                  @upgrade="upgrade"
                ></addon>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-show="addonData">
          <v-col>
            <v-card>
              <v-card-text>
                <addon-registration
                  :key="'arck' + addonRegistrationComponentKey"
                  v-on:addonchecked="revalidate()"
                  :addonData.sync="addonData"
                  :parentServiceId="id"
                  :parentTermId="serviceTermId"
                  :parentProductTerms="parentProductTerms"
                  v-on:addonPriceUpdate="updateAddonPrice"
                ></addon-registration>
                <v-row dense>
                  <v-col cols="11"></v-col>
                  <v-col cols="1">
                    <v-btn
                      text-align="right"
                      color="primary"
                      @click="buyAddons()"
                      :disabled="totalAddon === 0"
                      :loading="buying"
                    >Buy ${{ totalAddon }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
  <v-dialog
    v-model="cancelDialog"
    width="1024"
  >
    <v-card>
      <v-card-text>
        <v-row v-if="!cancelled">
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <h3 class="text-center">Your domain is locked
                  and secured through our system. In order to unlock or get EPP code, you agree to
                  discontinue the renewal. Please ensure that you complete your domain transfer
                  before your domain expiry.</h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-row>
              <v-col cols="3">
                <v-btn color="primary" small @click="toggleLock()" v-if="form.locked"
                       :loading="lockLoading">Unlock</v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn color="primary" small @click="getEpp" :loading="eppLoading">EPP Code</v-btn>
              </v-col>
              <v-col cols="3">
                <b v-if="epp">{{ epp }}</b>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="cancelDialog = false"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!cancelled"
          color="error"
          text
          :loading="cancelLoading"
          @click="agreeCancel()"
        >
          I agree
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <service-renew-dialog
    v-if="showServiceRenewDialog"
    v-on:close-dialog="showServiceRenewDialog = false"
    :service-id="renewServiceId"
  ></service-renew-dialog>
  <service-upgrade-downgrade-dialog
    v-if="showServiceUpgradeDialog"
    v-on:close-dialog="showServiceUpgradeDialog = false"
    :service-id="upgradeServiceId"
  ></service-upgrade-downgrade-dialog>
  <service-cancel-dialog
    v-if="showServiceCancelDialog"
    v-on:close-dialog="showServiceCancelDialog = false"
    :service-id="cancelServiceId"
  ></service-cancel-dialog>
</v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';
import AddonRegistration from '../../components/Addon.vue';
import Addon from '../../components/ServiceAddon.vue';
import ServiceRenewDialog from '../../components/dialogs/ServiceRenewDialog.vue';
import ServiceUpgradeDowngradeDialog from '../../components/dialogs/ServiceUpgradeDowngradeDialog.vue';
import ServiceCancelDialog from '../../components/dialogs/ServiceCancelDialog.vue';

export default {
  name: 'Detail',
  components: {
    Profile,
    AddonRegistration,
    Addon,
    ServiceRenewDialog,
    ServiceUpgradeDowngradeDialog,
    ServiceCancelDialog,
  },
  created() {
    this.id = +this.$route.params.id;
    this.$api.get('partner/domain-product/list').then((response) => {
      this.orderableProducts = response.data;
      this.reloadDomain();
      this.reloadDnssec();
    });
  },
  data() {
    return {
      id: null,
      showPage: false,
      epp: null,
      form: {
        domain: '',
        product_id: null,
        product_term: null,
        status_name: '',
        tld: null,
        auto_renew: true,
        locked: true,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        provision_date: '',
        expiry_date: '',
        registrant: {},
        admin: {},
        tech: {},
        billing: {},
      },
      childNss: [],
      addChildNss: [],
      deleteChildNss: [],
      updateChildNss: [],
      domainDnssec: [],
      addDomainDnssec: [],
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tldPriceList: [],
      terms: [],
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: false,
      eppLoading: false,
      autoRenewLoading: false,
      lockLoading: false,
      updateNsLoading: false,
      updateRegistrantLoading: false,
      updateAdminLoading: false,
      updateTechLoading: false,
      updateBillingLoading: false,
      updateChildNsLoading: false,
      renewDialog: false,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      successMessage:
        'We are registering your domain now. '
        + 'An email will be sent to you when your service is ready.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        childNs: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(`${value}.${this.form.domain}`) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        ip: (value) => (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value) || 'This field must be valid IP.',
      },

      orderableProducts: [],
      addonData: null,
      serviceTermId: null,
      parentProductTerms: [],
      addonRegistrationComponentKey: 1,
      totalAddon: 0,
      buying: false,
      service: null,

      showServiceRenewDialog: false,
      renewServiceId: null,
      showServiceUpgradeDialog: false,
      upgradeServiceId: null,
      showServiceCancelDialog: false,
      cancelServiceId: null,
      cancelDialog: false,
      cancelLoading: false,
      cancelled: false,
      canEpp: true,
      canChangeOtherContact: false,
      canViewOtherContact: false,
      canChangeRegContact: false,
      canViewRegContact: false,
      updateDnssecLoading: false,
    };
  },
  methods: {
    cancelPromp() {
      this.cancelDialog = true;
    },
    agreeCancel() {
      this.cancelLoading = true;
      this.$api.delete(`partner/service/${this.id}`)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have successfully discontinued to renew this service.',
          });
          this.cancelLoading = false;
          this.cancelled = true;
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.cancelLoading = false;
        });
    },
    buyAddons() {
      this.buying = true;
      this.$api.post('partner/addon/register', {
        service_id: this.id,
        domain: this.form.domain,
        addons: this.addonData,
        addon_only: true,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: this.successMessage,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .finally(() => {
          this.buying = false;
        });
    },
    reloadDomain() {
      this.$api.get(`partner/domain/${this.$route.params.id}?with_addon_info=1`).then((response) => {
        const domainData = response.data;
        this.serviceTermId = this.$gceCommon.getTermIdByTermMonth(domainData.service_month_term);
        this.service = domainData;
        this.form.domain = domainData.service_identity;
        this.form.product_id = domainData.product_id;
        this.form.provision_date = domainData.provision_date;
        this.form.status_name = domainData.service_status_name;
        this.form.expiry_date = domainData.expiry_date;
        this.form.auto_renew = domainData.auto_renew === 1;
        this.canEpp = domainData.domain_config.product_configuration.allow_directly_retrieve_epp;
        const registrarId = domainData.domain_config.service_configuration.registrar_id;
        if (registrarId === 8) {
          this.canViewRegContact = true;
        }
        this.form.locked = domainData.domain_config.service_configuration.locked === 1;
        this.form.ns1 = domainData.domain_config.service_configuration.ns1;
        this.form.ns2 = domainData.domain_config.service_configuration.ns2;
        this.form.ns3 = domainData.domain_config.service_configuration.ns3;
        this.form.ns4 = domainData.domain_config.service_configuration.ns4;
        this.form.ns5 = domainData.domain_config.service_configuration.ns5;
        this.$set(this, 'registrant', domainData.domain_config.service_configuration.registrant);
        this.$set(this, 'admin', domainData.domain_config.service_configuration.admin);
        this.$set(this, 'tech', domainData.domain_config.service_configuration.tech);
        this.$set(this, 'billing', domainData.domain_config.service_configuration.billing);
        this.$set(this, 'addChildNss', []);
        this.$set(this, 'deleteChildNss', []);
        this.$set(this, 'childNss', domainData.domain_config.service_configuration.child_nameservers);
        this.showPage = true;
        this.$underscore.each(this.orderableProducts, (products) => {
          this.$underscore.each(products, (product) => {
            if (product.id === domainData.product_id) {
              this.$underscore.each(product.currencies, (value) => {
                const currentProductPrice = {
                  text: `${value.termName}: $${value.productPrice}`,
                  value: value.productTermId,
                  price: value.productPrice,
                };
                this.parentProductTerms.push(currentProductPrice.value);
              });
            }
          });
        });
      });
    },
    checkDomain() {
      this.showRegistrationForm = false;
      this.domainErr = '';
      this.checkDomainLoading = true;
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.form.tld) {
          this.$underscore.map(tld.terms, (term, index) => {
            this.form.product_term = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            yearPrice = `${yearPrice} $${term.price}`;
            this.terms.push({
              text: `${index} ${yearPrice}`,
              value: term.term_id,
            });
          });
        }
      });

      this.$api.post('partner/domain/check-availability', this.form).then(() => {
        this.loadRegistrationForm();
      })
        .catch(() => {
          this.domainErr = 'The domain is not available.';
        })
        .finally(() => {
          this.checkDomainLoading = false;
        });
    },
    loadRegistrationForm() {
      this.showRegistrationForm = true;
    },
    showDomainPriceTable() {
      this.showPriceTable = true;
    },
    checkout() {
      this.checkoutProcessing = true;
      this.checkoutDisabled = true;
      if (this.$refs.form.validate()) {
        this.$underscore.map(this.tldPriceList, (value) => {
          if (value.tld_id === this.form.tld) {
            this.form.product_id = value.id;
          }
        });
        this.form.registrant = this.$refs.registrantProfileComp.getProfileData();
        this.form.admin = this.$refs.adminProfileComp.getProfileData();
        this.form.tech = this.$refs.techProfileComp.getProfileData();
        this.form.billing = this.$refs.billingProfileComp.getProfileData();
        this.$api.post('partner/domain/register', this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
            this.$emit('reloadActivity');
            this.reset();
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.checkoutProcessing = false;
            this.checkoutDisabled = false;
          });
      } else {
        this.checkoutProcessing = false;
        this.checkoutDisabled = false;
      }
    },
    copyContact(from, to) {
      this[to] = this.$refs[`${from}ProfileComp`].getProfileData();
    },
    reset() {
      this.$router.go(0);
    },
    toggleAutoRenew() {
      this.autoRenewLoading = true;
      this.$api.post('partner/service/set-auto-renew', {
        id: this.id,
        auto_renew: this.form.auto_renew ? 0 : 1,
      }).then(() => {
        this.$set(this.form, 'auto_renew', !this.form.auto_renew);
        const successMessage = this.form.auto_renew ? 'on' : 'off';
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `Auto renew is turned ${successMessage}.`,
        });
      }).finally(() => {
        this.autoRenewLoading = false;
      });
    },
    toggleLock() {
      this.lockLoading = true;
      this.$api.post(`partner/domain/${this.$route.params.id}/set-lock`, {
        locked: this.form.locked ? 0 : 1,
      }).then(() => {
        this.$set(this.form, 'locked', !this.form.locked);
        const successMessage = this.form.locked ? 'on' : 'off';
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `Lock is turned ${successMessage}.`,
        });
      }).finally(() => {
        this.lockLoading = false;
      });
    },
    getEpp() {
      this.eppLoading = true;
      this.$api.get(`partner/domain/${this.$route.params.id}/epp`).then((response) => {
        this.epp = response.data;
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `EPP code: ${response.data}`,
        });
      }).finally(() => {
        this.eppLoading = false;
      });
    },
    updateNs() {
      this.updateNsLoading = true;
      this.$api.post(`partner/domain/${this.$route.params.id}/update-nameservers`, {
        ns1: this.form.ns1,
        ns2: this.form.ns2,
        ns3: this.form.ns3,
        ns4: this.form.ns4,
        ns5: this.form.ns5,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'NS is updated successfully.',
        });
      }).finally(() => {
        this.updateNsLoading = false;
      });
    },
    updateRegistrant() {
      this.updateRegistrantLoading = true;
      this.$api.post(`partner/domain/${this.$route.params.id}/update-registrant-contact`,
        this.$refs.registrantProfileComp.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Registrant contact is updated successfully.',
        });
      }).finally(() => {
        this.updateRegistrantLoading = false;
      });
    },
    updateAdmin() {
      this.updateAdminLoading = true;
      this.$api.post(`partner/domain/${this.$route.params.id}/update-admin-contact`,
        this.$refs.adminProfileComp.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Admin contact is updated successfully.',
        });
      }).finally(() => {
        this.updateAdminLoading = false;
      });
    },
    updateTech() {
      this.updateTechLoading = true;
      this.$api.post(`partner/domain/${this.$route.params.id}/update-tech-contact`,
        this.$refs.techProfileComp.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Tech contact is updated successfully.',
        });
      }).finally(() => {
        this.updateTechLoading = false;
      });
    },
    updateBilling() {
      this.updateBillingLoading = true;
      this.$api.post(`partner/domain/${this.$route.params.id}/update-billing-contact`,
        this.$refs.billingProfileComp.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Billing contact is updated successfully.',
        });
      }).finally(() => {
        this.updateBillingLoading = false;
      });
    },
    addChildNs() {
    },
    updateChildNs() {
    },
    deleteChildNs() {
    },
    openRenewDialog() {
      this.renewalTerms.splice(0, this.renewalTerms.length);
      this.renewDialog = true;
      this.$api.post('partner/service/get-renewal-terms', {
        id: this.id,
      }).then((response) => {
        response.data.terms.forEach((term, index) => {
          this.renewalTerms.push({
            text: `${term.term_name} $${term.product_price}`,
            value: term.id,
          });

          if (index === 0) {
            this.renewalTerm = term.id;
          }
        });
      }).finally(() => {
      });
    },
    renew(id) {
      this.renewServiceId = id;
      this.showServiceRenewDialog = true;
    },
    upgrade(id) {
      this.upgradeServiceId = id;
      this.showServiceUpgradeDialog = true;
    },
    cancel(id) {
      this.cancelServiceId = id;
      this.showServiceCancelDialog = true;
    },
    revalidate() {
    },
    updateAddonPrice(amount) {
      this.totalAddon = +amount;
    },
    oldRenew() {
      this.renewCheckoutLoading = true;
      this.$api.post('partner/service/renew', {
        id: this.id,
        product_term: this.renewalTerm,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your renewal request is submitted successfully.',
        });
        this.renewDialog = false;
        this.loadTable();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.renewCheckoutLoading = false;
      });
    },
    addChildNameserver() {
      this.addChildNss.push({
        subdomain: '',
        ip: '',
      });
    },
    updateChildNameserver() {
      this.updateChildNsLoading = true;
      if (this.$refs.updateChildNsForm.validate()) {
        this.$api.post(`partner/domain/${this.$route.params.id}/update-child-nameservers`, {
          add_child_nameservers: this.addChildNss,
          delete_child_nameservers: this.deleteChildNss,
          child_nameservers: this.childNss,
        })
          .then(() => {
            this.reloadDomain();
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Child nameservers are updated successfully.',
            });
          })
          .finally(() => {
            this.updateChildNsLoading = false;
          });
      } else {
        this.updateChildNsLoading = false;
      }
    },
    removeChildNs(index) {
      this.deleteChildNss.push(this.childNss.splice(index, 1)[0]);
    },
    addDNSSEC() {
      this.addDomainDnssec.push({
        keytag: '',
        digest: '',
      });
    },
    reloadDnssec() {
      this.$api.get(`partner/domain/${this.$route.params.id}/domain-dnssec`)
        .then((response) => {
          const localDnssec = response.data;
          this.$set(this, 'domainDnssec', localDnssec);
          this.$set(this, 'addDomainDnssec', []);
        });
    },
    saveDomainDnssec() {
      this.updateDnssecLoading = true;
      if (this.$refs.updateDNSSECForm.validate()) {
        this.$api.post(`partner/domain/${this.$route.params.id}/new-domain-dnssec`, {
          add_dnssec: this.addDomainDnssec[0],
        })
          .then(() => {
            this.reloadDnssec();
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Dnssec is created successfully.',
            });
          })
          .finally(() => {
            this.updateDnssecLoading = false;
          });
      } else {
        this.updateDnssecLoading = false;
      }
    },
    removeDnssec() {
      // eslint-disable-next-line no-alert
      const r = window.confirm('Are you sure to remove DNSSEC?');
      if (r === true) {
        this.$api.post(`partner/domain/${this.$route.params.id}/delete-domain-dnssec`, {})
          .then(() => {
            this.reloadDnssec();
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Dnssec is removed successfully.',
            });
          });
      }
    },
  },
  watch: {
    'form.domain': {
      handler(domain) {
        if (this.rules.domain(domain) !== true) {
          this.checkDomainInvalid = true;
        } else {
          this.checkDomainInvalid = false;
          this.domainErr = null;
        }
      },
    },
  },
};
</script>

<style scoped>

</style>
