import Base from './Base.vue';
import Home from './Home.vue';
import PartnerList from './partner/List.vue';
import PartnerClientList from './partner/RetailClientList.vue';
import PartnerAdd from './partner/Add.vue';
import PartnerDetail from './partner/Detail.vue';
import PartnerProductMemberPrices from './partner/ProductMemberPrices.vue';
import PartnerAddonMemberPrices from './partner/AddonMemberPrices.vue';
import PartnerDomainProductMemberPrices from './partner/DomainProductMemberPrices.vue';
import MemberOnlyRetailProducts from './partner/MemberOnlyRetailProducts.vue';
import ServerList from './server/List.vue';
import ServerAdd from './server/Add.vue';
import ServerDetail from './server/Detail.vue';
import ServerGroupList from './server_group/List.vue';
import ServerGroupAdd from './server_group/Add.vue';
import ServerGroupDetail from './server_group/Detail.vue';
import ProductList from './product/List.vue';
import ProductAdd from './product/Add.vue';
import ProductDetail from './product/Detail.vue';
import AddonList from './addon/List.vue';
import AddonAdd from './addon/Add.vue';
import AddonDetail from './addon/Detail.vue';
import ProductTypeList from './product_type/List.vue';
import ProductTypeAdd from './product_type/Add.vue';
import ProductTypeDetail from './product_type/Detail.vue';
import DomainProductList from './domain_product/List.vue';
import DomainProductAdd from './domain_product/Add.vue';
import DomainProductDetail from './domain_product/Detail.vue';
import ServiceManualProvisionQueueList from './service_manual_provision_queue/List.vue';
import ServiceManualProvisionQueueDetail from './service_manual_provision_queue/Detail.vue';
import PartnerPaymentHistory from './payment/PartnerPaymentHistory.vue';
import ManualInvoice from './payment/ManualInvoice.vue';
import ImportCpanelService from './service/ImportCpanelService.vue';
import ImportDomainService from './service/ImportDomainService.vue';
import ImportManualService from './service/ImportManualService.vue';
import BulkImportCpanelService from './service/BulkImportCpanelService.vue';
import ListActiveServices from './service/ListActiveServices.vue';
import ServiceCpanelUpdate from './service/ServiceCpanelUpdate.vue';
import ServiceDetail from './service/ServiceDetail.vue';
import DomainDetail from './service/DomainDetail.vue';
import ListActiveDomains from './service/ListActiveDomains.vue';
import Whois from './utility/Whois.vue';
import ArticleList from './article/List.vue';
import ArticleAdd from './article/Add.vue';
import ArticleUpdate from './article/Update.vue';
import ArticleRevisionUpdate from './article/UpdateRevision.vue';
import ArticleCategoryList from './article/ListCategory.vue';
import ArticleCategoryAdd from './article/AddCategory.vue';
import ArticleCategoryUpdate from './article/UpdateCategory.vue';
import AffiliateList from './affiliate/List.vue';
import AffiliateReview from './affiliate/Review.vue';
import AffiliateCommissionList from './affiliate/CommissionList.vue';
import AffiliateCommissionWithdrawList from './affiliate/CommissionWithdrawList.vue';
import AffiliateCommissionWithdrawDetail from './affiliate/CommissionWithdrawDetail.vue';
import PartnerCommissionList from './commission/CommissionList.vue';
import PartnerCommissionWithdrawList from './commission/CommissionWithdrawList.vue';
import PartnerCommissionWithdrawDetail from './commission/CommissionWithdrawDetail.vue';
import DiscountList from './discount/List.vue';
import DiscountAdd from './discount/Add.vue';
import RevenueReport from './report/RevenueReport.vue';
import StripeNoMatchingPayments from './finance/StripeNoMatchingPayments.vue';
import DomainAuditDreamscape from './domain_audit/DomainAuditDreamscape.vue';
import DomainAuditResellerclub from './domain_audit/DomainAuditResellerclub.vue';
import DomainAuditWebnic from './domain_audit/DomainAuditWebnic.vue';
import DomainAuditServerRenewProhibited
  from './domain_audit/DomainAuditServerRenewProhibited.vue';
import AuditPendingServices from './audit/AuditPendingServices.vue';
import AuditPendingDomains from './audit/AuditPendingDomains.vue';
import AuditUnpaidInvoices from './audit/AuditUnpaidInvoices.vue';
import CpanelDiskusage from './service/CpanelDiskusage.vue';
import AuditNearExpiryManualServices from './audit/AuditNearExpiryManualServices.vue';
import AuditNotSuspendCpanel from './audit/AuditNotSuspendCpanel.vue';
import ToggleExpired from './service/ToggleExpired.vue';
import PartnerCreditDeduction from './payment/PartnerCreditDeduction.vue';
import Above2kReport from './report/Above2kReport.vue';
import UpdateAuthUsers from './partner/UpdateAuthUsers.vue';
import ListAuthUsers from './partner/ListAuthUsers.vue';
import ForceDsDomainAdminChange from './service/ForceDsDomainAdminChange.vue';
import VendorList from './service/VendorList.vue';
import CancelFirstcomService from './service/CancelFirstcomService.vue';
import TaxInvoiceList from './payment/TaxInvoiceList.vue';
import CpanelServerRevenueMonthly from './report/CpanelServerRevenueMonthly.vue';
import QueryDomainContact from './service/QueryDomainContact.vue';
import ListMigrationServices from './service/ListMigrationServices.vue';
import ServiceCpanelSync from './service/ServiceCpanelSync.vue';
import CldyNewCustomerReport from './report/CldyNewCustomerReport.vue';
import NotifyCustomer from './utility/NotifyCustomer.vue';
import ZeroYearTransfer from './service/ZeroYearTransfer.vue';
import ZeroYearTransferSgnic from './service/ZeroYearTransferSgnic.vue';
import QuerySgnicDomainContact from './service/QuerySgnicDomainContact.vue';

export default {
  Home,
  Base,
  PartnerList,
  PartnerClientList,
  PartnerAdd,
  PartnerDetail,
  PartnerProductMemberPrices,
  PartnerAddonMemberPrices,
  PartnerDomainProductMemberPrices,
  MemberOnlyRetailProducts,
  ServerList,
  ServerAdd,
  ServerDetail,
  ServerGroupList,
  ServerGroupAdd,
  ServerGroupDetail,
  ProductList,
  ProductAdd,
  ProductDetail,
  AddonList,
  AddonAdd,
  AddonDetail,
  DomainProductList,
  DomainProductAdd,
  DomainProductDetail,
  ProductTypeList,
  ProductTypeAdd,
  ProductTypeDetail,
  ServiceManualProvisionQueueList,
  ServiceManualProvisionQueueDetail,
  PartnerPaymentHistory,
  ManualInvoice,
  ImportCpanelService,
  ImportManualService,
  ImportDomainService,
  BulkImportCpanelService,
  ListActiveServices,
  ServiceCpanelUpdate,
  ServiceDetail,
  DomainDetail,
  ListActiveDomains,
  ArticleAdd,
  ArticleUpdate,
  ArticleRevisionUpdate,
  ArticleList,
  ArticleCategoryAdd,
  ArticleCategoryUpdate,
  ArticleCategoryList,
  AffiliateReview,
  AffiliateList,
  AffiliateCommissionList,
  AffiliateCommissionWithdrawList,
  AffiliateCommissionWithdrawDetail,
  PartnerCommissionList,
  PartnerCommissionWithdrawList,
  PartnerCommissionWithdrawDetail,
  DiscountList,
  DiscountAdd,
  Whois,
  RevenueReport,
  StripeNoMatchingPayments,
  DomainAuditDreamscape,
  DomainAuditResellerclub,
  DomainAuditWebnic,
  DomainAuditServerRenewProhibited,
  AuditPendingServices,
  AuditPendingDomains,
  AuditUnpaidInvoices,
  AuditNearExpiryManualServices,
  AuditNotSuspendCpanel,
  CpanelDiskusage,
  ToggleExpired,
  PartnerCreditDeduction,
  Above2kReport,
  UpdateAuthUsers,
  ListAuthUsers,
  ForceDsDomainAdminChange,
  VendorList,
  CancelFirstcomService,
  TaxInvoiceList,
  CpanelServerRevenueMonthly,
  QueryDomainContact,
  ListMigrationServices,
  ServiceCpanelSync,
  CldyNewCustomerReport,
  NotifyCustomer,
  ZeroYearTransfer,
  ZeroYearTransferSgnic,
  QuerySgnicDomainContact,
};
