<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <v-text-field
            dense
            outlined
            v-model="domain"
            label="Domain"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <v-btn
            color="primary"
            @click="queryDomain"
          >Query</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense align="center" v-show="showPage">
        <v-col cols="12">
          <v-row>
            <v-col cols="3">
              <h3>{{ form.domain }}</h3>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <h5>Status:<br>{{ form.status }}</h5>
            </v-col>
            <v-col cols="3">
              <h5>Registration Date:<br>{{ form.registration_date }}</h5>
            </v-col>
            <v-col cols="3">
              <h5>Expiry Date:<br>{{ form.expiry_date }}</h5>
            </v-col>
            <v-col cols="3">
            </v-col>
          </v-row>
          <v-form ref="updateNsForm">
            <v-row dense>
              <v-col cols="3">
                <v-text-field
                  label="NS1*"
                  v-model="form.ns1"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="NS2*"
                  v-model="form.ns2"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col cols="3">
                <v-text-field
                  label="NS3"
                  v-model="form.ns3"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="NS4"
                  v-model="form.ns4"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="NS5"
                  v-model="form.ns5"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row dense>
            <v-col cols="6">
              <v-form>
                <h5>Registrant Contact</h5>
                <profile-sg
                  ref="registrantProfileCompSg"
                  :force-org="forceOrgAdmin"
                  :setNewProfile="registrant"
                ></profile-sg>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      :loading="updateRegistrantLoading"
                      @click="updateRegistrant"
                    >Update</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ProfileSg from '../../components/ProfileSg.vue';

export default {
  name: 'QuerySgnicDomainContact',
  components: {
    ProfileSg,
  },
  created() {
    this.$api.get('partner/domain-product/list').then((response) => {
      this.orderableProducts = response.data;
    });
  },
  data() {
    return {
      profile: 1,
      profileContact: this.$refs.registrantProfileComp,
      domain: '',
      id: null,
      showPage: false,
      epp: null,
      form: {
        domain: '',
        status: '',
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        registration_date: '',
        expiry_date: '',
        registrant: {},
      },
      childNss: [],
      addChildNss: [],
      deleteChildNss: [],
      updateChildNss: [],
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tldPriceList: [],
      terms: [],
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: false,
      eppLoading: false,
      autoRenewLoading: false,
      lockLoading: false,
      updateNsLoading: false,
      updateRegistrantLoading: false,
      updateAdminLoading: false,
      updateTechLoading: false,
      updateBillingLoading: false,
      updateChildNsLoading: false,
      renewDialog: false,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      successMessage:
        'We are registering your domain now. '
        + 'An email will be sent to you when your service is ready.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        childNs: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(`${value}.${this.form.domain}`) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        ip: (value) => (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value) || 'This field must be valid IP.',
      },

      orderableProducts: [],
      addonData: null,
      serviceTermId: null,
      parentProductTerms: [],
      addonRegistrationComponentKey: 1,
      totalAddon: 0,
      buying: false,
      service: null,

      showServiceRenewDialog: false,
      renewServiceId: null,
      showServiceUpgradeDialog: false,
      upgradeServiceId: null,
      showServiceCancelDialog: false,
      cancelServiceId: null,
      cancelDialog: false,
      cancelLoading: false,
      cancelled: false,
      forceOrgAdmin: false,
    };
  },
  methods: {
    queryDomain() {
      this.$api.post('admin/service/query-sgnic-domain-id', {
        domain: this.domain,
      })
        .then((response) => {
          if (parseInt(response.data.id, 10) !== 0) {
            this.id = response.data.id;
            this.$set(this.form, 'domain', this.domain);
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: 'Domain not found.',
            });
          }
        });
    },
    reloadDomain() {
      this.$api.get(`admin/service/query-sgnic-domain/${this.id}`).then((response) => {
        const domainData = response.data;
        this.form.registration_date = domainData.registration_date;
        this.form.status = domainData.status;
        this.form.expiry_date = domainData.expiry_date;
        this.form.ns1 = domainData.ns1;
        this.form.ns2 = domainData.ns2;
        this.form.ns3 = domainData.ns3;
        this.form.ns4 = domainData.ns4;
        this.form.ns5 = domainData.ns5;
        this.$set(this, 'registrant', domainData.registrant);
        this.showPage = true;
      });
    },
    reset() {
      this.$router.go(0);
    },
    updateRegistrant() {
      console.log(this.profileContact);
      this.updateRegistrantLoading = true;
      this.$api.post(`admin/service/domain/${this.id}/update-registrant-contact`,
        this.profileContact.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Registrant contact is updated successfully.',
        });
      }).finally(() => {
        this.updateRegistrantLoading = false;
      });
    },
  },
  watch: {
    'form.domain': {
      handler(domain) {
        this.profile = 2;
        this.profileContact = this.$refs.registrantProfileCompSg;
        if (domain.indexOf('.com.sg') > 0
          || domain.indexOf('.org.sg') > 0
          || domain.indexOf('.edu.sg') > 0
        ) {
          this.forceOrgAdmin = true;
        } else {
          this.forceOrgAdmin = false;
        }
        this.reloadDomain();
      },
    },
  },
};
</script>

<style scoped>

</style>
